<template>
  <div>
    <CRow>
      <CCol sm="4">
        <h4 class="text-uppercase">Pricing</h4>
      </CCol>

      <CCol sm="8"> </CCol>
    </CRow>

    <CRow>
      <CCol sm="12">
        <CCard>
          <CRow class="pb-3">
            <CCol sm="10">

            </CCol>

            <CCol sm="2" class="text-right">
              <router-link
                class="btn btn-primary"
                to="/bills/prices/create"
              >
                + New Pricing
              </router-link>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="12">
              <div class="table-responsive">
                <table class="table table-borderless">
                  <thead>
                    <tr class="text-center">
                      <th scope="col" class="text-left">Effective From</th>
                      <th scope="col" class="text-left">Pricing <br> (Category/Unit Price)</th>
                      <th scope="col">Due Date Duration <br> (days)</th>
                      <th scope="col">VAT</th>
                      <th scope="col">VAT Reg. No</th>
                      <th scope="col">Surcharges</th>
                      <th scope="col">Min. Charge</th>
                      <th scope="col">Service Charge</th>
                      <th scope="col">Installment Interests</th>
                      <th scope="col">Created At</th>
                    </tr>
                  </thead>
                  <tbody v-if="prices.length">
                    <tr
                      class="text-center"
                      v-for="item in prices"
                      :key="item.id"
                    >

                      <td scope="row" class="text-left">{{ dateFormat(item.effective_from) }}</td>
                      <td scope="row" class="text-left">
                        <ul class="price-list">
                          <li v-for="(price, index) in item.pricings" :key="index"><strong>{{ price.category }}</strong> {{ formatPrice(price.unit_price) }}</li>
                        </ul>
                      </td>
                      <td scope="row">{{ item.due_date_duration }}</td>
                      <td scope="row">{{ formatPrice(item.min_charge_value) }} ({{ item.min_charge_type }})</td>
                      <td scope="row">{{ formatPrice(item.service_charge) }}</td>
                      <td scope="row">
                        <ul class="price-list">
                          <li v-for="(surcharge, index) in item.surcharges" :key="index"><strong>Month: </strong>{{ surcharge.month_no }}{{nth(surcharge.month_no)}}, <strong>Charge: </strong>{{ formatPrice(surcharge.charge) }}%</li>
                        </ul>
                      </td>
                      <td scope="row">
                        <ul class="price-list">
                          <li v-for="(installment, index) in item.installment_interests" :key="index"><strong>Month: </strong>{{ installment.month_no }}{{nth(installment.month_no)}}, <strong>Charge: </strong>{{ formatPrice(installment.charge) }}%</li>
                        </ul>
                      </td>
                      <td scope="row">{{ item.vat }}%</td>
                      <td scope="row">{{ item.vat_reg_no }}</td>
                      <td scope="row">{{ dateFormat(item.created_at) }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="10">
                        <h5 class="text-center">Data Not Available.</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CCol>
          </CRow>
        </CCard>
      </CCol>
    </CRow>


  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PriceListComp",
  methods: {
    dateFormat(date) {
      return moment(date).format("Do MMM, YYYY");
    },
    nth(n) {
      return["st","nd","rd"][((n+90)%100-10)%10-1]||"th"
    }
  },
  computed: {
    ...mapGetters("Pricing", ["prices"]),
  },
  mounted() {
    this.$store.dispatch("Pricing/getPrices");
  },
};
</script>

<style scoped>
.card {
  border: none;
  padding: 15px 15px 0;
}
tbody:before {
  content: "@";
  display: block;
  line-height: 0;
  text-indent: -99999px;
}
.hide-footer >>> footer {
  display: none;
}
.custom-close-button >>> .close {
  color: aliceblue;
}
</style>

<style lang="scss" scoped>
.badge {
  font-size: 12px;
  padding: 5px 7px;
}
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
          vertical-align: middle;
        }
      }
    }
    tbody {
      font-size: 13px;
      tr {
        color: #000;
        td {
          padding: 0.25rem 0 0.25rem 0.75rem;
          vertical-align: middle;
          button {
            font-size: 13px;
          }
          span {
            cursor: pointer;
          }
          span .color-view {
            color: #5e76e7;
            padding: 10px;
          }
        }
        td:nth-child(1) {
          color: #5e76e7;
        }
        td:nth-child(2) {
          font-weight: 500 !important;
        }
      }
    }
  }
}
.price-list {
  list-style-type: none;
  padding: 8px 15px;
  background-color: #efefef;
  border-radius: 6px;
  font-size: 14px;
}
</style>



